import React, { useState, useEffect } from "react";
import { Box, Flex, Text, keyframes } from "@chakra-ui/react";
import ScoreAnimation from "./ScoreAnimation";
import { type RoundType } from "./types";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const GameHeader: React.FC<{
  currentRoundIndex: number;
  gameLength: number | null;
  currentRound: RoundType;
  marginTop: string;
  isSmallScreen: boolean;
  hintCount: number;
}> = ({
  currentRoundIndex,
  gameLength,
  currentRound,
  isSmallScreen,
  hintCount,
}) => {
  const [hintText, setHintText] = useState<string>("");
  const [hintKey, setHintKey] = useState(0);

  useEffect(() => {
    if (hintCount > 0) {
      const delay = 100; // Small delay before updating hint text
      const timeoutId = setTimeout(() => {
        const bestPossibleWords = currentRound.bestPossibleWords.split(",");
        const revealedWords = bestPossibleWords.slice(0, hintCount + 1);
        const joinedWords = revealedWords.join(" → ");
        setHintText(joinedWords);
        setHintKey((prev) => prev + 1); // Increment key to trigger re-render and new animation
      }, delay);
      return () => clearTimeout(timeoutId);
    }
  }, [hintCount, currentRound.bestPossibleWords]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      width="93%"
      mb={isSmallScreen ? 0 : 4}
    >
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <Flex direction="column" alignItems="center">
          <Value>
            {currentRoundIndex + 1}/{gameLength}
          </Value>
          <Label>ROUND</Label>
        </Flex>
        {hintCount > 0 && (
          <Box
            key={hintKey} // Use key to trigger re-render and new animation
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            minH="35px"
            mx={2}
            animation={`${fadeIn} 0.6s`} // 0.6s fade-in animation
          >
            <Text fontSize="sm" color="gray.700">
              {hintText}
            </Text>
          </Box>
        )}
        {gameLength !== null && (
          <Flex
            direction="column"
            alignItems="center"
            cursor="pointer"
            borderRadius={5}
          >
            <Value>
              <ScoreAnimation
                movesLength={currentRound.moves.length}
                currentRound={currentRound}
              />
            </Value>
            <Label>MOVES</Label>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

const Value: React.FC<{ children: any }> = ({ children }) => (
  <Box as="div" fontSize="2xl" fontWeight="bold" color="orangeBrand.500">
    {children}
  </Box>
);

const Label: React.FC<{ children: any }> = ({ children }) => (
  <Box
    fontSize="2xs"
    fontWeight="semibold"
    textTransform="uppercase"
    color="gray.500"
  >
    {children}
  </Box>
);

export default GameHeader;
